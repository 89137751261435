import {
  AutoMargin,
  Button,
  Col,
  FaIcon,
  Grid,
  H1,
  H2,
  H3,
  Hbox,
  Row,
  Separator
} from 'atomic'
import { Query } from 'site/src/data/graphql/graphql-types'
import { graphql, Link } from 'gatsby'
import * as React from 'react'
import { Badge } from 'atomic/legacy/atm.badge/badge.component'
import { Breadcrumb } from 'site/src/components/atm.breadcrumb/breadcrumb.component'
import { TopStickyWrapper } from 'site/src/components/atm.wrapper/wrapper.component'
import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import { ChapterNavigation } from 'site/src/components/mol.chapter-navigation/chapter-navigation.component'
import {
  ScrollInfo,
  TableOfContents
} from 'site/src/components/mol.table-of-contents/table-of-contents.component'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths } from 'utils/path'
import { getSearchQueryParam, normalizeUrl } from 'utils/url'

export interface ChapterIndex {
  title: string
  url: string
}

export interface DiagnosticManualDetailTemplateProps {
  tags: string[]
  manualTitle: string
  chapterTitle: string
  scrollInfo: ScrollInfo[]
  breadcrumbDictionary?: { [id: string]: string }
  body: string
  chapterIndex: ChapterIndex[]
  seoDescription: string
  seoImage: string
  seoImageAlt: string
}

class DiagnosticManualDetailTemplate extends React.Component<
  PageProps<DiagnosticManualDetailTemplateProps, Query>
> {
  render() {
    const chapterIndex = this.props.pageContext.chapterIndex
    const currentPath = normalizeUrl(this.props.location.pathname)
    const currentPageIndex = chapterIndex.findIndex(
      chapter => chapter.url === currentPath
    )
    const currentPageIsFirstPage = currentPageIndex === 0
    const currentPageIsLastPage = currentPageIndex === chapterIndex.length - 1

    // the index path is the current pathname without its last component
    const indexPath = normalizeUrl(`${this.props.location.pathname}/`)
      .split('/')
      .slice(0, -1)
      .join('/')

    return (
      <IndexLayout location={this.props.location}>
        <SEO
          socialMedia={{
            canonicalUrl: `${this.props.data.site.siteMetadata.siteUrl}${this.props.location.pathname}${this.props.location.search}`,
            title: this.props.pageContext.chapterTitle,
            image: this.props.pageContext.seoImage,
            imageAlt: this.props.pageContext.seoImageAlt,
            description: this.props.pageContext.seoDescription
          }}
        />
        <Grid>
          <Row mb>
            <Col xs={12}>
              <Separator />
              <Breadcrumb
                addtionalDictionary={
                  this.props.pageContext.breadcrumbDictionary
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Separator />
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row mb>
            <Col xs={false} sm={false} md={4}>
              <TopStickyWrapper>
                <Separator />
                <H2 cell>{this.props.pageContext.manualTitle}</H2>
                <ChapterNavigation
                  currentPath={currentPath}
                  chapterIndex={chapterIndex}
                />
              </TopStickyWrapper>
            </Col>
            <Col xs={12} sm={10} smOffset={1} mdOffset={1} md={7}>
              <Separator />
              <H1>{this.props.pageContext.chapterTitle}</H1>
              <Hbox>
                <Hbox.Item wrap>
                  <Button
                    id='button-diagnostic-see-index'
                    to={indexPath}
                    kind={'link'}
                  >
                    Ver índice
                  </Button>
                </Hbox.Item>
              </Hbox>
              <Separator />
              {this.props.pageContext.scrollInfo.length !== 0 && (
                <>
                  <H3>Tópicos presentes neste capítulo</H3>
                  <TableOfContents
                    scrollInfo={this.props.pageContext.scrollInfo}
                  />
                </>
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.pageContext.body
                }}
              />

              {this.props.pageContext.tags && (
                <Row mt>
                  {this.props.pageContext.tags.map(tag => (
                    <>
                      <Col>
                        <Link
                          to={`${
                            appPaths.search.path.doctorUrl
                          }${getSearchQueryParam(tag)}`}
                        >
                          <Badge text={tag} kind='secondary' />
                        </Link>
                        <Separator />
                      </Col>
                    </>
                  ))}
                </Row>
              )}
            </Col>
          </Row>
          <Row mb between={'xs'}>
            <Col xs={12} mdOffset={5} md={7}>
              <Hbox>
                {!currentPageIsFirstPage && (
                  <>
                    <Hbox.Item wrap>
                      <Button
                        id='button-back-icon-diagnostic'
                        to={
                          chapterIndex[currentPageIndex - 1]
                            ? chapterIndex[currentPageIndex - 1].url
                            : ''
                        }
                        kind={'link'}
                      >
                        <FaIcon.ArrowLeft size='lg' /> Anterior
                      </Button>
                    </Hbox.Item>
                  </>
                )}
                {!currentPageIsLastPage && (
                  <>
                    <AutoMargin />
                    <Hbox.Item wrap>
                      <Button
                        id='button-forward-icon-diagnostic'
                        kind={'link'}
                        to={chapterIndex[currentPageIndex + 1].url}
                      >
                        Próximo <FaIcon.ArrowRight size='lg' />
                      </Button>
                    </Hbox.Item>
                  </>
                )}
              </Hbox>
            </Col>
          </Row>
        </Grid>
      </IndexLayout>
    )
  }
}

export default DiagnosticManualDetailTemplate

export const query = graphql`
  query DiagnosticManualDetailTemplate {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
